<template>
  <div>
    <b-row>
      <b-col>
        <Login
          :radioGroupClass="true"
          ref="reader"
          size="lg"
          :labelForAuth="true"
          @onDetection="onDetection"
          @updateModel="updateModel"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Login from './Login';
import RestResourceUsuario from '@/services/usuario';

export default {
  name: 'UserAuthentication',
  inject: ['validator'],
  created() {
    this.$validator = this.validator;
  },
  components: {
    Login,
  },
  data() {
    return {
      typeInput: 'password',
      model: {
        registro: '',
        password: '',
        permission: 'rw_registro_entrada',
      },
      isBusy: false,
    };
  },

  props: {},

  methods: {
    onDetection() {
      this.authenticate();
    },

    updateModel(val) {
      this.model = { ...val };
    },

    async authenticate() {
      const validationResult = await this.$validator.validateAll();
      if (!validationResult) return validationResult;

      let result;
      this.$emit('isBusy', true);
      try {
        if (!this.model.code && !this.model.password && !this.model.registro) {
          this.$emit('isBusy', false);
          return false;
        }

        if (this.model.code) {
          result = await RestResourceUsuario.authUserByUuid(
            this.model,
          );
        } else {
          result = await RestResourceUsuario.authUserByLoginProps(
            this.model,
          );
        }

        this.$refs.reader.clearFields();
        this.model = {};
        this.$emit('isBusy', false);
      } catch (error) {
        let errorMessage = 'Ocorreu um erro ao realizar a autenticação';
        if (
          error.response
          && error.response.data
          && error.response.data.error
          && error.response.data.error.errorMessage
        ) {
          errorMessage = error.response.data.error.errorMessage;
        }

        swal({
          icon: 'error',
          text: errorMessage,
          buttons: {
            confirm: 'OK',
          },
        });
        this.$emit('isBusy', false);

        return false;
      }

      if (result && result.length) {
        this.$emit('authenticationResult', result[0]);
        return true;
      }

      swal({
        title: 'Registro não autenticado',
        text:
          'Registro ou senha incorretos ou o usuário não possui permissão para realizar essa ação',
        icon: 'error',
        button: 'Continuar',
      });

      return false;
    },

    validateState(ref) {
      if (
        this.veeFields[ref]
        && document.getElementsByName(ref)[0]
        && !document.getElementsByName(ref)[0].disabled
        && (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
  },
};
</script>

<style scoped>
*:focus {
  outline: 0 !important;
}

.iconeShowPass {
  border: 1px;
  border-color: black;
  border-radius: 5px;
  color: white;
}

.inlineBtn {
  border: 1px solid #ced4da;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}
</style>
