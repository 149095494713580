import api from './api';

export default {

  updateActive(id, ativo) {
    return api.execute('put', `/api/usuario/ativo/${id}`, null, { ativo });
  },
  verificaSeRegistroJaExiste(registro) {
    return api.execute('get', `/api/usuario/registro/${registro}`, null, null, true);
  },
  authUserByUuid(data) {
    return api.execute('post', '/api/auth/authUserByUuid', null, data);
  },
  authUserByLoginProps(data) {
    return api.execute('post', '/api/auth/authUserByLoginProps', null, data);
  },
};
